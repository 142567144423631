<template>
  <div>
    <v-row>
      <v-col cols="3" class="text-center">
        <h3 class="mb-4">Маршрут в договоре</h3>
      </v-col>
      <v-spacer />

      <v-col cols="3">
        <v-menu
          ref="Дата закрытия"
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          min-width="290px"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormated"
              class="mt-3"
              label="Дата закрытия"
              dense
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="date"
            no-title
            :show-current="true"
            :first-day-of-week="1"
            :weekday-format="dayOfWeekToFormat"
          >
            <v-spacer/>
            <v-btn
              text
              color="primary"
              @click="dateMenu = false"
            >
              Ок
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-btn
          color="primary"
          @click="addRoute"
        >
          Добавить
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      height="400"
      loading-text="Загрузка списка маршрутов"
      item-key="name"
      fixed-header
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.action="{ item }">
        <DeleteRouteItem
          :item="item"
          @onRemove="removeRoute"
        ></DeleteRouteItem>
      </template>
    </v-data-table>

    <v-row dense>
      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="confirmAdd"
          :loading="loadingConfirm"
          :disabled="items.length === 0"
        >
          Применить
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import DeleteRouteItem from "./DeleteRouteItem";
  import GeneralServices from "@/services/GeneralServices";
  import {dayOfWeekToFormat} from "@/services/JetDate";

export default {
  name: "Routes",
  components: { DeleteRouteItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loadingConfirm: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Начало действия",
          value: "startDate",
        },
        {
          text: "Окончание действия",
          value: "endDate",
        },
        {
          text: "Маршрут",
          value: "fullName",
        },
        {
          text: "Примечание",
          value: "note",
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      currentRoute: null,
      currentConsist: null,
      applyRoute: null,
      date: new Date().toISOString().substr(0, 10),
      dateMenu: null,
      dayOfWeekToFormat: () => '',
    };
  },
  computed: {
    dateFormated () {
      return GeneralServices.formatDate(this.date);
    },
    filteredConsist() {
      if (this.currentRoute) {
        return this.consists.filter((consist) => {
          if (
            consist.routeId === this.currentRoute.id &&
            consist.type === 1
          ) {
            this.currentConsist = consist;
          }

          return consist.routeId === this.currentRoute.id;
        });
      } else {
        return this.consists;
      }
    },
  },
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
  },
  methods: {
    consistText(item) {
      return item.name + " " + item.type;
    },
    changeRoute() {
      this.currentConsist = null;
    },
    addRoute() {
      this.$emit("onAdd");
    },
    removeRoute(route) {
      this.$emit("onRemove", route);
    },
    confirmAdd() {
      this.$emit("onConfirm");
      this.$emit("date", this.date)
    },
  },
};
</script>
